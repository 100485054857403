import { createMuiTheme } from '@material-ui/core/styles';

import Theme from './login-theme';

const SmallBorderRadius = 8;

interface CreateThemeProps {
    primaryMainColor?: string;
    primaryHoverColor?: string;
    secondaryTextColor?: string;
    primeBorderColor?: string;
    errorBackgroundColor?: string;
    loginBackgroundColor?: string;
    primaryTextColor?: string;
    contentBackgroundColor?: string;
    selectBackgroundColor?: string;
    authMenuBackGround?: string;
}

export function createTheme(props: CreateThemeProps): Theme {
    let {
        primaryMainColor = '#389FCC',
        primaryHoverColor = '#2680A7',
        primaryTextColor = '#FFFFFF',
        secondaryTextColor = '#7A7C8F',
        primeBorderColor = '#000',
        errorBackgroundColor = '#FF0000',
        loginBackgroundColor = '#293C45',
        contentBackgroundColor = 'grey',
        selectBackgroundColor = '#1B2C37',
        authMenuBackGround = '#031B25',
    } = props;

    let theme: Theme = createMuiTheme({
        palette: {
            primary: {
                main: primaryMainColor,
            },
            text: {
                secondary: secondaryTextColor,
            },
        },
    });

    theme.overrides = {
        MuiContainer: {
            root: {
                backgroundColor: theme.palette.background.paper,
            },
        },
        MuiList: {
            root: {
                backgroundColor: loginBackgroundColor,
            },
        },
        MuiMenuItem: {
            root: {
                '&:hover': {
                    backgroundColor: selectBackgroundColor,
                },
            },
        },
        MuiSelect: {
            select: {
                '&:focus': {
                    backgroundColor: loginBackgroundColor,
                },
                '&:hover': {
                    backgroundColor: loginBackgroundColor,
                },
            },
            icon: {
                color: contentBackgroundColor,
            },
        },
    };

    theme.custom = {
        colors: {
            background: {
                error: errorBackgroundColor,
            },
        },
        languageSelect: {
            containerBlock: {
                display: 'flex',
            },
            itemSelect: {
                display: 'flex',
                alignItems: 'center',
                marginLeft: '4px',
            },
            itemFlag: {
                display: 'flex',
                marginRight: '16px',
            },
            itemName: {
                fontSize: '16px',
                color: primaryTextColor,
                marginLeft: '8px',
            },
            select: {
                width: '305px',
                border: 'none',
                fontSize: '16px',
                backgroundColor: loginBackgroundColor,
                '& .itemFlag': {
                    display: 'none',
                },
                cursor: 'pointer',
            },
            menuItem: {
                fontSize: '16px',
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: loginBackgroundColor,
            },
        },
        inputField: {
            root: {
                width: 455,
                height: 58,
                display: 'flex',
                alignItems: 'center',
                borderRadius: SmallBorderRadius,
                padding: '0 8px',
                backgroundColor: theme.palette.background.paper,
                boxSizing: 'border-box',
                '&:hover': {
                    borderColor: theme.palette.primary.main,
                },
            },
            fullWidth: {
                width: `calc(100% - ${theme.spacing(1) * 2}px) !important`,
            },
            error: {
                cursor: 'pointer',
            },
            input: {
                flexGrow: 1,
                border: 'none',
                backgroundColor: theme.palette.background.paper,
                outline: 'none',
                fontFamily: 'inherit',
                fontSize: 16,
                fontWeight: 600,
                color: theme.palette.text.primary,
                '&::placeholder': {
                    color: secondaryTextColor,
                    fontSize: 16,
                    fontWeight: 500,
                },
                caretColor: primaryTextColor,
            },
            inputField: {
                display: 'flex',
                fontWeight: 'normal',
                backgroundColor: loginBackgroundColor,
                color: primaryTextColor,
                '&:-webkit-autofill': {
                    display: 'flex',
                    textFillColor: `${primaryTextColor} !important`,
                    boxShadow: `0 0 0 30px ${loginBackgroundColor} inset !important`,
                },
            },
            additionalLoginInputStyle: {
                backgroundColor: loginBackgroundColor,
                padding: '0 8px 0 8px',
                cursor: 'text',
            },
            additionalPasswordInputStyle: {
                fontWeight: 'normal',
                backgroundColor: loginBackgroundColor,
                color: primaryTextColor,
                marginRight: '10px',
                padding: '0 8px 0 8px',
                '&:-webkit-autofill': {
                    textFillColor: `${primaryTextColor} !important`,
                    boxShadow: `0 0 0 30px ${loginBackgroundColor} inset !important`,
                },
                cursor: 'text',
            },
            typeIconBlock: {
                height: 20,
                '& svg path': {
                    fill: theme.palette.text.secondary,
                },
                margin: '19px',
            },
            iconContainer: {
                cursor: 'pointer',
            },
        },
        loginSwitch: {
            switch: {
                width: 66,
                height: 50,
                padding: 0,
                '& .MuiSwitch-switchBase': {
                    padding: 0,
                    margin: 15,
                    transitionDuration: '300ms',
                    '&.Mui-checked': {
                        transform: 'translateX(16px)',
                        color: primaryTextColor,
                        '& + .MuiSwitch-track': {
                            backgroundColor: primaryMainColor,
                            opacity: 1,
                            border: 0,
                        },
                        '&.Mui-disabled + .MuiSwitch-track': {
                            opacity: 0.5,
                        },
                    },
                    '&.Mui-focusVisible .MuiSwitch-thumb': {
                        color: primaryTextColor,
                        border: `6px solid ${primaryTextColor}`,
                    },
                    '&.Mui-disabled .MuiSwitch-thumb': {
                        color: 'pink',
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                        width: 66,
                        height: 50,
                        opacity: 0.7,
                        backgroundColor: 'blue',
                    },
                },
                '& .MuiSwitch-thumb': {
                    color: primaryTextColor,
                    boxSizing: 'border-box',
                    width: 20,
                    height: 20,
                },
                '& .MuiSwitch-track': {
                    maxWidth: 42,
                    minWidth: 42,
                    height: 26,
                    borderRadius: 12,
                    backgroundColor: loginBackgroundColor,
                    opacity: 1,
                },
            },
            switchBlock: {
                display: 'flex',
                flexDirection: 'row',
                height: '60px',
                color: primaryTextColor,
                alignItems: 'center',
            },
        },
        content: {
            contentBlock: {
                display: 'flex',
                position: 'relative',
                backgroundColor: contentBackgroundColor,
                width: '100%',
                color: primaryTextColor,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            },
            contentLeft: {
                display: 'flex',
                flex: 1,
                position: 'relative',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
            },
            contentLeftNotNav: {
                display: 'flex',
                flex: 1,
                position: 'relative',
                justifyContent: 'center',
                alignItems: 'center',
            },
            contentCenter: {
                display: 'flex',
                flex: 10,
                flexDirection: 'column',
                position: 'relative',
            },
            contentRight: {
                display: 'flex',
                flex: 1,
                position: 'relative',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
            },
            contentRighNotNav: {
                display: 'flex',
                flex: 1,
                position: 'relative',
                justifyContent: 'center',
                alignItems: 'center',
            },
            description: {
                display: 'flex',
                left: '10px',
                height: '80%',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                marginBottom: '8px',
            },
            imageList: {
                display: 'flex',
                flexDirection: 'row',
                height: '90px',
                justifyContent: 'space-between',
            },
            previewList: {
                display: 'flex',
                flexDirection: 'row',
            },
            nextButton: {
                display: 'flex',
                width: 42,
                height: 42,
                '&:hover': {
                    transform: 'scale(1.2, 1.2)',
                    transition: 'all 0.3s',
                },
                '& path': {
                    fill: primaryTextColor,
                },
            },
            prevButton: {
                display: 'flex',
                width: 42,
                height: 42,
                '&:hover': {
                    transform: 'scale(1.2, 1.2)',
                    transition: 'all 0.3s',
                },
                '& path': {
                    fill: primaryTextColor,
                },
            },
            descriptionTitle: {
                height: '72px',
                fontSize: '36px',
                marginBottom: '25px',
            },
            descriptionText: {
                maxHeight: '100%',
                fontSize: '16px',
                whiteSpace: 'pre-line',
                marginBottom: '25px',
            },
            descriptionLink: {
                height: '25px',
                fontSize: '16px',
                textDecoration: 'none',
            },
            descriptionLinkColor: {
                color: primaryTextColor,
            },
            imageItem: {
                border: `1px solid ${primeBorderColor}`,
                height: '90px',
                width: '105px',
                marginRight: '8px',
                borderRadius: '4px',
                cursor: 'pointer',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                '&:hover': {
                    zIndex: 5,
                    transform: 'scale(1.1, 1.1)',
                    transition: 'all 0.3s',
                },
            },
            selectedImage: {
                border: `1px solid ${primaryTextColor}`,
                height: '90px',
                width: '105px',
                marginRight: '8px',
                borderRadius: '2px',
                cursor: 'pointer',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                transform: 'scale(1.1, 1.1)',
                transition: 'all 0.3s',
            },
        },
        logo: {
            width: 160,
            height: 40,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            padding: 0,
        },
        flagIcon: {
            width: 20,
            height: 20,
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
            padding: 0,
        },
        errorIcon: {
            width: 20,
            height: 20,
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
            padding: 0,
            '& path': {
                fill: errorBackgroundColor,
            },
        },
        storeIcon: {
            display: 'flex',
            cursor: 'pointer',
        },
        menuAuth: {
            backgroundBlock: {
                backgroundColor: authMenuBackGround,
                minWidth: '550px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            auth: {
                display: 'flex',
                flexDirection: 'column',
                top: '150px',
            },
            appLinks: {
                display: 'flex',
                gap: '10px',
                bottom: 0,
            },
            logoBlock: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '381px',
                minHeight: '730px',
            },
            greeting: {
                height: '69px',
                width: '100%',
                color: primaryTextColor,
                marginBottom: '36px',
            },
            greetingHeader: {
                fontWeight: 600,
                fontSize: '36px',
                lineHeight: '44px',
                marginBottom: '6px',
            },
            greetingText: {
                fontWeight: 300,
                fontSize: '16px',
                lineHeight: '19px',
            },
            loginBlock: {
                display: 'flex',
                flexDirection: 'row',
                width: '370px',
                height: '60px',
                borderRadius: '8px',
                color: primaryTextColor,
                paddingRight: '10px',
                marginBottom: '15px',
                opacity: 1,
                backgroundColor: loginBackgroundColor,
            },
            passBlock: {
                display: 'flex',
                flexDirection: 'row',
                width: '380px',
                height: '60px',
                borderRadius: '8px',
                marginBottom: '15px',
                opacity: 1,
                backgroundColor: loginBackgroundColor,
                color: primaryTextColor,
            },
            languageBlock: {
                display: 'flex',
                flexDirection: 'row',
                width: '380px',
                height: '60px',
                backgroundColor: loginBackgroundColor,
                borderRadius: '8px',
                color: primaryTextColor,
                marginBottom: 0,
            },
        },
        popUpError: {
            errorIconBlock: {
                margin: '19px',
                display: 'flex',
                alignItems: 'center',
                width: '20px',
                height: '20px',
                '& path': {
                    fill: errorBackgroundColor,
                },
            },
            errorBlock: {
                display: 'flex',
                flexDirection: 'row',
                width: '370px',
                minHeight: '60px',
                border: `1px ${loginBackgroundColor} solid`,
                borderRadius: '8px',
                paddingRight: '10px',
                marginBottom: '15px',
                opacity: 1,
            },
            errorText: {
                display: 'flex',
                alignItems: 'center',
                color: primaryTextColor,
                paddingLeft: '8px',
            },
        },
        dividerBlock: {
            width: '1px',
            background: secondaryTextColor,
            opacity: 0.3,
        },
        visibilityIcon: {
            display: 'flex',
            '& path': {
                fill: secondaryTextColor,
            },
        },
        loading: {
            root: {
                flex: '1 1 auto',
                borderRadius: '2px',
                backgroundColor: theme.palette.background.paper,
                boxShadow: theme.shadows[5],
                outline: 'none',
                '&:focus': {
                    border: 'none',
                },
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            transparentRoot: {
                flex: '1 1 auto',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
        },
        buttons: {
            contained: {
                boxSizing: 'border-box',
                minWidth: 105,
                height: 60,
                borderRadius: SmallBorderRadius,
                padding: '0 12px',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: primaryMainColor,
                color: primaryTextColor,
                fontSize: 14,
                fontWeight: 600,
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: primaryHoverColor,
                },
                '& svg': {
                    marginRight: 8,
                },
                '& path': {
                    fill: primaryTextColor,
                },
            },
        },
    };

    return theme;
}

export let defaultDarkTheme = createTheme({});
